import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { NavLink } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import LandingPage from "examples/LandingPage";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";


import lusowo from "assets/images/lusowo-3076.png";
import tarnowo from "assets/images/tarnowo-3076.png";
import przezmierowo from "assets/images/przezmierowo.png";

import {isMobile} from 'react-device-detect';

//Modals
import React from 'react'
import ReactPlayer from 'react-player'
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";


function Dashboard() {
  const [openLusowo, setOpenLusowo] = React.useState(false);
  const handleOpenLusowo = () => setOpenLusowo(true);
  const handleCloseLusowo = () => setOpenLusowo(false);
  const [openTarnowo, setOpenTarnowo] = React.useState(false);
  const handleOpenTarnowo = () => setOpenTarnowo(true);
  const handleCloseTarnowo = () => setOpenTarnowo(false);
  const [openPrzezmierowo, setOpenPrzezmierowo] = React.useState(false);
  const handleOpenPrzezmierowo = () => setOpenPrzezmierowo(true);
  const handleClosePrzezmierowo = () => setOpenPrzezmierowo(false);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <LandingPage />
      {/* TODO: move Modals to a component */}
      <Modal
        open={openLusowo}
        onClose={handleCloseLusowo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          aspectRatio: '16/9',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <MDBox display='flex' flex={1} justifyContent='space-between' alignContent='center'>
            <Typography id="modal-modal-title" variant="h4" component="h4">
              Film o przedszkolu w Lusowie
            </Typography>
            <IconButton size="large" aria-label="close" color="inherit" onClick={handleCloseLusowo}>
              <Icon fontSize="large">close</Icon>
            </IconButton>
          </MDBox>
          <ReactPlayer
            url={'http://chatkakubusiapuchatka.pl/videos/Lusowo.mp4'}
            playing={true}
            controls={true}
            width="100%"
            height="100%"
          />
        </Box>
      </Modal>
      <Modal
        open={openTarnowo}
        onClose={handleCloseTarnowo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          aspectRatio: '16/9',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <MDBox display='flex' flex={1} justifyContent='space-between' alignContent='center'>
            <Typography id="modal-modal-title" variant="h4" component="h4">
              Film o przedszkolu w Tarnowie Podgórnym
            </Typography>
            <IconButton size="large" aria-label="close" color="inherit" onClick={handleCloseTarnowo}>
              <Icon fontSize="large">close</Icon>
            </IconButton>
          </MDBox>
          <ReactPlayer
            url={'http://chatkakubusiapuchatka.pl/videos/TarnowoPodgorne.mp4'}
            playing={true}
            controls={true}
            width="100%"
            height="100%"
          />
        </Box>
      </Modal>
      <Modal
        open={openPrzezmierowo}
        onClose={handleClosePrzezmierowo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          aspectRatio: '16/9',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <MDBox display='flex' flex={1} justifyContent='space-between' alignContent='center'>
            <Typography id="modal-modal-title" variant="h4" component="h4">
            Film o przedszkolu w Przeźmierowie
            </Typography>
            <IconButton size="large" aria-label="close" color="inherit" onClick={handleClosePrzezmierowo}>
              <Icon fontSize="large">close</Icon>
            </IconButton>
          </MDBox>
          <ReactPlayer
            url={'http://chatkakubusiapuchatka.pl/videos/Przezmierowo.mp4'}
            playing={true}
            controls={true}
            width="100%"
            height="100%"
          />
        </Box>
      </Modal>
      <MDBox maxWidth={1000} margin={"auto"} >
        <Grid container spacing={3} alignItems="stretch" mt={3}>
          <Grid item xs={12} md={6} lg={4} style={{display: 'flex'}} mt={10}>
            <SimpleBlogCard
              action={{
                type: "internal",
                route: "/",
                facebook: isMobile ? "fb://profile/100057277366805" : "https://www.facebook.com/profile.php?id=100057277366805",
                maps: 'https://goo.gl/maps/KfVcbzART4yzBiG57',
                phone: "tel:618963159",
                color: "lusowo",
                label: "Zobacz film",
                button: handleOpenLusowo
              }}
              title="Lusowo"
              address='ul. Skośna 9, 62-080 Lusowo'
              phone="61 896 31 59"
              image={lusowo}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{display: 'flex'}} mt={10}>
            <SimpleBlogCard
              action={{
                type: "internal",
                route: "/",
                facebook: isMobile ? "fb://profile/100057439989980" : "https://www.facebook.com/profile.php?id=100057439989980",
                maps: "https://goo.gl/maps/WYyFRtZSwuy4xJnQ6",
                phone: "tel:618164988",
                color: "tarnowo",
                label: "Zobacz film",
                button: handleOpenTarnowo
              }}
              title="Tarnowo Podgórne"
              address="ul. Z. Romaszewskiego 4, 62-080 Tarnowo Podgóme"
              phone= "61 816 49 88"
              image={tarnowo}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{display: 'flex'}} mt={10}>
            <SimpleBlogCard
              action={{
                type: "internal",
                route: "/",
                facebook: isMobile ? "fb://profile/100057468578732" : "https://www.facebook.com/profile.php?id=100057468578732",
                maps: "https://goo.gl/maps/C9UZJTgocPAa3Zrz8",
                phone: "tel:616523928",
                color: "przezmierowo",
                label: "Zobacz film",
                button: handleOpenPrzezmierowo
              }}
              title="Przeźmierowo"
              address="ul. Składowa 45, 62-081 Przeźmierowo"
              phone= "61 652 39 28"
              image={przezmierowo}
            />
          </Grid>
        </Grid>
      </MDBox>
      
    </DashboardLayout>
  );
}

export default Dashboard;
