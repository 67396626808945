/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import News from "layouts/news";
import About from "layouts/about";
import Contact from "layouts/contact";
import Privacy from "layouts/privacy";
import Enroll from "layouts/enroll";
import EnrollThisYear from "layouts/enrollThisYear";
import Authentication from "layouts/authentication/sign-in";
import InsertPost from "layouts/authentication/insertPost";

// @mui icons
import Icon from "@mui/material/Icon";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';


const routes = [
  {
    type: "collapse",
    name: "Strona główna",
    key: "dashboard",
    icon: <Icon fontSize="large">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "O przedszkolu",
    key: "about",
    icon: <Icon fontSize="large">article</Icon>,
    route: "/oprzedszkolu",
    component: <About />,
  },
  {
    type: "collapse",
    name: "Aktualności",
    key: "news",
    icon: <Icon fontSize="large">newspaper</Icon>,
    route: "/aktualnosci",
    component: <News />,
  },
  {
    type: "collapse",
    name: "Kontakt",
    key: "contact",
    icon: <AlternateEmailIcon fontSize="large" />,
    route: "/kontakt",
    component: <Contact />,
  },
  {
    name: "Prywatność",
    key: "prywatność",
    route: "/politykaprywatnosci",
    component: <Privacy />,
  },
  {
    type: "collapse",
    name: "Zapisy",
    key: "zapisy1",
    icon: <Icon fontSize="large">assignment</Icon>,
    route: "/zapisy",
    component: <Enroll />,
  },
  // {
  //   type: "collapse",
  //   name: "Rekrutacja 2024/25",
  //   key: "zapisy2",
  //   icon: <Icon fontSize="large">assignment</Icon>,
  //   route: "/rekrutacja",
  //   component: <EnrollThisYear />,
  // },
  {
    type: "route",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="large">assignment</Icon>,
    route: "/logowanie",
    component: <Authentication />,
  },
  {
    type: "route",
    name: "InsertPost",
    key: "insertPost",
    icon: <Icon fontSize="large">assignment</Icon>,
    route: "/wstawPost",
    component: <InsertPost />,
  },
];

export default routes;
