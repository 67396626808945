import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

import PocketBase from 'pocketbase';

const styles = {
  activeTab: {
    opacity: 1,
    cursor: 'pointer',
    borderBottom: '3px solid',
    paddingBottom: 3,
  },
  inactiveTab: {
    opacity: 0.6,
    cursor: 'pointer',
  },
  titles: {
    fontWeight: "bold",
    variant: "h3",
    whiteSpace: "nowrap",
  },
  mobileTitles: {
    fontWeight: "bold",
    fontSize: "0.8rem",
    variant: "h6"
  },
}


function News() {
  const [activeTab, setActiveTab] = useState('lusowo');
  const [data, setData] = useState([]);
  const pb = new PocketBase('https://atom.pockethost.io/');

  useEffect(() => {
    async function getFilteredData() {
      await pb.collection('posts').getFullList({
        sort: '-date',
        filter: `preschool="${activeTab}"`,
      }).then((res) => setData(res));
    }
    getFilteredData();
  }, [activeTab]);

  function formatDate(inputDate) {
    const months = [
        "stycznia", "lutego", "marca", "kwietnia", "maja", "czerwca",
        "lipca", "sierpnia", "września", "października", "listopada", "grudnia"
    ];

    const date = new Date(inputDate);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = `${day} ${months[monthIndex]} ${year}`;

    return formattedDate;
}

  const renderCards = () => {
    return data?.map((card, index) => (
      <Grid key={index} item xs={12} mt={7} alignItems="stretch">
        <DefaultProjectCard
          action={{
            type: "external",
            route: card.linkToFBPost,
            color: "przezmierowo",
          }}
          title={card.title !== 'undefined' ? card.title : card.description !== 'undefined' ? card.description : ''}
          label={formatDate(card.date)}
          description={card.title === 'undefined' ? '' : card.description}
          image={`https://atom.pockethost.io/api/files/posts/${card.id}/${card.photo}`}
        />
      </Grid>
    ))
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox maxWidth={1000} margin={"auto"} mt={3}>
        <MDTypography display="inline" variant="h3" fontWeight="bold">
          Ostatnie wydarzenia
        </MDTypography>
        <MDBox mt={3} mb={3} display="flex" flexWrap="wrap" columnGap={{xs: 1, md: 4}}>
          <MDBox style={activeTab==='lusowo'?styles.activeTab:styles.inactiveTab} >
            <MDTypography
              component="span"
              onClick={() => setActiveTab('lusowo')}
              style={styles.titles}
            >
              Lusowo
            </MDTypography>
          </MDBox>
          <MDBox style={activeTab==='tarnowo'?styles.activeTab:styles.inactiveTab}>
            <MDTypography
              component="span"
              onClick={() => setActiveTab('tarnowo')}
              style={styles.titles}>
              Tarnowo Podgórne
            </MDTypography>
          </MDBox>
          <MDBox style={activeTab==='przezmierowo'?styles.activeTab:styles.inactiveTab}>
            <MDTypography
              component="span"
              onClick={() => setActiveTab('przezmierowo')}
              style={styles.titles}>
              Przeźmierowo
            </MDTypography>
          </MDBox>
        </MDBox>

        
        <Grid container spacing={3} alignItems="stretch">
          {renderCards()}
        </Grid>
      </MDBox>
      
    </DashboardLayout>
  );
}

export default News;
