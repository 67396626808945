import MDBox from "../../components/MDBox";
import classes from "./LandingPage.module.css";
import illustrationDesktop from '../../assets/newGraphics/best-transparent-name-l.png';
import illustrationTablet from '../../assets/newGraphics/best-transparent-name-m.png';
import illustrationMobile from '../../assets/newGraphics/best-transparent-name-s.png';

const LandingPage = () => {
  return (
    <>
      <MDBox sx={({ breakpoints }) => ({
        display: 'none',

        [breakpoints.up("lg")]: {
          display: 'flex',
        },
      })} className={classes["landing-page"]}>
        <img className={classes["landing-page-box"]} src={illustrationDesktop} alt={"main"} />
      </MDBox>
      <MDBox sx={({ breakpoints }) => ({
        display: 'none',

        [breakpoints.up("sm")]: {
          display: 'flex',
        },
        [breakpoints.up("lg")]: {
          display: 'none',
        },
      })} className={classes["landing-page"]}>
        <img className={classes["landing-page-box"]} src={illustrationTablet} alt={"main"} />
      </MDBox>
      <MDBox sx={({ breakpoints }) => ({
        display: 'flex',

        [breakpoints.up("sm")]: {
          display: 'none',
        },
      })} className={classes["landing-page"]}>
        <img className={classes["landing-page-box"]} src={illustrationMobile} alt={"main"} />
      </MDBox>
    </>
  );
};

export default LandingPage;
