// import Image from 'next/image';
import MDBox from "../../components/MDBox";
import MDTypography from "components/MDTypography";

export function TextPlusImage(props) {
  return (
    <MDBox
      display="flex"
      w={1}
      gap={4}
      flexDirection={{xs: "column", md: props.imageOnRight ? 'row-reverse' : 'row'}}
    >
      <MDBox
        width={1/3}
        justify="center"
        m="auto"
      >
        <MDBox component="img" src={props.image} alt="img1" display="flex"
          sx={{
            height: 350,
            maxHeight: { xs: 233, md: 350 },
          }}
        />
      </MDBox>
      <MDBox
        w={{xs: 1, md: 2/3}}
        display="flex"
        justify="center"
        textAlign="justify"
        alignItems="center"
      >
        <MDTypography>
          {props.text}
        </MDTypography>
      </MDBox>
    </MDBox>
  )
}
