// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { NavLink } from "react-router-dom";

import PhoneLink from "components/Links/PhoneLink";
import EmailLink from "components/Links/EmailLink";

import { useLocation } from 'react-router-dom';


function Footer() {
  const location = useLocation();

  return (
    <MDBox
      width="calc(100% + 3rem)"
      display="flex"
      flexDirection={{ xs: "column"}}
      justifyContent="space-between"
      alignItems="space-between"
      backgroundColor="#a59772"
      m={-3}
      mt={9}
    >
      <MDBox
        sx={({ breakpoints }) => ({
          backgroundColor: "#a59772",
          display: "flex",
          flex: 1,
          flexWrap: "wrap",
          flexDirection: {xs: "column-reverse", xl: "row"},
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mb: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        <MDBox alignItems="center" m={3} mt={{xs: 0, xl: 3}}>
          <Link href={'https://tomczyk.vercel.app'} target="_blank">
            <MDTypography variant="button" fontWeight="regular" color="white">
              Copyright © 2023 Adam Tomczyk
            </MDTypography>
          </Link>
        </MDBox>
        <MDBox display="flex" flexDirection={{xs: "column", md: 'row'}} alignItems="center" m={3} >
          <PhoneLink label="500 262 850" link="tel:500262850" color="white" mr={3} />
          <EmailLink label="przedszkole.rekrutacja@op.pl" link="mailto:przedszkole.rekrutacja@op.pl" color="white" mr={3} mt={{xs: 3, md: 0}} />
          {(location.pathname !== '/zapisy' && location.pathname !== '/rekrutacja') &&
            <MDBox component={NavLink} to="/zapisy">
              <MDButton color="maintext" sx={({ breakpoints }) => ({
                minWidth: '180px',
                [breakpoints.up("lg")]: {
                  mt: 0,
                },
                [breakpoints.down("md")]: {
                  mt: 3,
                },
                })} >
                Zapisz swoje dziecko
              </MDButton>
            </MDBox>
          }
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://tomczyk.vercel.app", name: "Adam Tomczyk" },
  links: [
    { href: "https://tomczyk.vercel.app", name: "Adam Tomczyk" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
