// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

//mui components
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//Icons
import EmailIcon from '@mui/icons-material/Email';


function EmailLink({ color, link, label, ...rest }) {
  return (
    <MuiLink href={link} target="_blank" rel="noreferrer">
      <MDBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        {...rest}
      >
        <EmailIcon color={color} fontSize="medium" />
        <MDTypography variant="body2" component="p" color={color} pl={1}>
          {label}
        </MDTypography>
      </MDBox>
    </MuiLink>
  )};

// Setting default values for the props of EmailLink
EmailLink.defaultProps = {
  color: "info",
  link: '',
  label: 'label'
};

// Typechecking props of the EmailLink
EmailLink.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "lusowo",
    "tarnowo",
    "przezmierowo"
  ]),
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default EmailLink;
