import { useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import PocketBase from 'pocketbase';
import { Typography } from "@mui/material";

import logo from "assets/newGraphics/logo-transparent.png";

function Basic() {
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [isError, setIsError] = useState(false);
  const pb = new PocketBase('https://atom.pockethost.io/');
  const navigate = useNavigate();

  const login = async () => {
    try {
      await pb.collection('users').authWithPassword(email, pass);
  
      if (pb.authStore.isValid) {
        setIsError(false);
        navigate("/wstawPost");
      }
      else {
        setIsError(true);
      }
    }
    catch (e) {
      setIsError(true);
    }
  }

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Logowanie
          </MDTypography>
        </MDBox>
        <MDBox component="img" src={logo} my={3} mx="auto" alt="Logo" width="10rem" />
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput value={email} onChange={e => setEmail(e.target.value)} type="email" label="Email" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput value={pass} onChange={e => setPass(e.target.value)} type="password" label="Hasło" fullWidth />
            </MDBox>
            {isError &&
              <MDBox mb={2}>
                <Typography variant="body2" color="error" align="center">
                  Nieprawidłowy email lub hasło
                </Typography>
              </MDBox>
            }
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={() => login()} >
                zaloguj się
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
