import { useState, useEffect } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import PocketBase from 'pocketbase';
import { Typography } from "@mui/material";

function Basic() {
  const [form, setForm] = useState({
    title: '',
    description: '',
    date: new Date().toISOString().slice(0,10),
    photo: '',
    linkToFBPost: '',
    preschool: 'lusowo',
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const pb = new PocketBase('https://atom.pockethost.io/');
  pb.autoCancellation(false);
  const navigate = useNavigate();

  const pushPost = async () => {
    try {
      const formData = new FormData();
      formData.append('title', form.title);
      formData.append('description', form.description);
      formData.append('date', form.date);
      formData.append('linkToFBPost', form.linkToFBPost);
      const fileInput = document.getElementById('fileInput');
      formData.append('photo', fileInput.files[0]);
      formData.append('preschool', form.preschool);
      
      
      await pb.collection('posts').create(formData).then((res) => {
        setIsSuccess(true);
        setErrorMessage('');
        }).catch((e) => {
          console.error(e);
      });
    }
    catch (e) {
      if (Object.keys(e.data.data).includes('title')) {
        setErrorMessage('Uzupełnij tytuł');
      }
      else if (Object.keys(e.data.data).includes('description')) {
        setErrorMessage('Uzupełnij opis');
      }
      else if (Object.keys(e.data.data).includes('photo')) {
        setErrorMessage('Wystąpił problem z wgraniem zdjęcia');
      }
      else {
        setErrorMessage('Wystąpił problem z wstawieniem postu');
      }
    }
  }

  useEffect(() => {
    if (!pb.authStore.isValid) {
      navigate("/logowanie");
    }
  }, []);

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Wstawianie postu
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput value={form.title} onChange={e => setForm({...form, title: e.target.value})} type="text" label="Tytuł" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput value={form.description} onChange={e => setForm({...form, description: e.target.value})} type="text" label="Opis" fullWidth />
            </MDBox>
            <MDBox mb={2} position="relative">
              <MDInput onChange={e => setForm({...form, date: e.target.value})} type="date" fullWidth />
              <InputLabel sx={{ marginLeft: '.8rem', fontSize: '.75rem', position: 'absolute', top: -9, padding: '3px', background: '#fff' }}>Data</InputLabel>
            </MDBox>
            <MDBox mb={2}>
              <FormControl fullWidth>
                <InputLabel id="preschoolLabel">Przedszkole</InputLabel>
                <Select
                  sx={{ minHeight: '2.5rem' }}
                  labelId="preschoolLabel"
                  id="preschoolInput"
                  value={form.preschool}
                  label="przedszkole"
                  onChange={e => setForm({...form, preschool: e.target.value})} 
                >
                  <MenuItem value={'lusowo'}>Lusowo</MenuItem>
                  <MenuItem value={'tarnowo'}>Tarnowo Podgórne</MenuItem>
                  <MenuItem value={'przezmierowo'}>Przeźmierowo</MenuItem>
                </Select>
              </FormControl>
            </MDBox>
            <MDBox mb={2} position="relative">
              <MDInput id="fileInput" value={form.photo} onChange={e => setForm({...form, photo: e.target.value})} type="file" fullWidth />
              <InputLabel sx={{ marginLeft: '.8rem', fontSize: '.75rem', position: 'absolute', top: -9, padding: '3px', background: '#fff' }}>Zdjęcie</InputLabel>
            </MDBox>
            <MDBox mb={2}>
              <MDInput value={form.linkToFBPost} onChange={e => setForm({...form, linkToFBPost: e.target.value})} type="text" label="Link do postu na facebooku" fullWidth />
            </MDBox>
            {errorMessage &&
              <MDBox mb={2}>
                <Typography variant="body2" color="error" align="center">
                  {errorMessage}
                </Typography>
              </MDBox>
            }
            {isSuccess &&
              <MDBox mb={2}>
                <Typography variant="body2" color="success" align="center">
                  Poprawnie dodano post
                </Typography>
              </MDBox>
            }
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={() => pushPost()} >
                wstaw post
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
