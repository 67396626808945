// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";


const renderButton = (action) => {
  if (action.label === undefined) return <></>;
  if (action.type === "external") {
    return (
      <MuiLink href={action.route} target="_blank" rel="noreferrer">
        <MDButton color={action.color ? action.color : "dark"}>
          {action.label}
        </MDButton>
      </MuiLink>
    )
  } else {
    return (
      // <Link to={action.route}>
      //   <MDButton color={action.color ? action.color : "dark"}>
      //     {action.label}
      //   </MDButton>
      // </Link>
      <MDButton color={action.color ? action.color : "dark"} onClick={action.button} >
        {action.label}
      </MDButton>
    )
  }
}

function SimpleBlogCard({ image, title, address, action, phone }) {
  return (
    <Card style={{width: '100%'}}>
      {image && <MDBox position="relative" borderRadius="lg" mt={-12} mx={2}>
        <MDBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          // shadow="md"
          width="100%"
          height="100%"
          position="relative"
          zIndex={1}
        />
        <MDBox
          borderRadius="lg"
          // shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top="3%"
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>}

      <MDBox
        p={3}
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "space-between",
        }}
      >
        <MDTypography display="inline" variant="h3" fontWeight="bold">
          {title}
        </MDTypography>
        <MuiLink href={action.maps} target="_blank" rel="noreferrer">
          <MDBox mt={2} display="flex" flexDirection="row" alignItems="center">
            <LocationOnIcon color={action.color} fontSize="medium" />
            <MDTypography variant="body2" component="p" color="maintext" pl={1}>
              {address}
            </MDTypography>
          </MDBox>
        </MuiLink>
        <MuiLink href={action.phone} target="_blank" rel="noreferrer">
          <MDBox
            mt={1}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <PhoneIcon color={action.color} fontSize="medium" />
            <MDTypography variant="body2" component="p" color="maintext" pl={1}>
              {phone}
            </MDTypography>
          </MDBox>
        </MuiLink>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 12,
          }}
        >
          {renderButton(action)}
          {action.facebook !== undefined &&
            <MDBox display="flex" flexDirection="row" alignItems="center">
              <MuiLink
                href={action.facebook}
                target="_blank"
                rel="noreferrer"
                display="flex"
                alignItems="center"
              >
                <FacebookOutlinedIcon color={action.color} fontSize="large" />
                {/* <FacebookOutlinedIcon color={action.color} fontSize="medium" />
                <MDTypography variant="body2" component="p" color="maintext" pl={1}>
                  Facebook
                </MDTypography> */}
              </MuiLink>
              {/* <InstagramIcon color={action.color} fontSize="large" /> */}
            </MDBox>
          }
        </div>
      </MDBox>
    </Card>
  );
}

// Typechecking props for the SimpleBlogCard
SimpleBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf(["lusowo", "tarnowo", "przezmierowo"]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default SimpleBlogCard;
